<template>
  <div class="flex-grow flex flex-col overflow-hidden">
    <!-- 聊天内容区域 -->
    <div ref="chatContainer" class="flex-grow overflow-y-auto mt-4 px-4 hide-scrollbar max-w-4xl w-full mx-auto">
      <!-- 消息列表 -->
      <div v-for="(message, index) in messages" :key="index">
        <!-- 用户消息 -->
        <div v-if="message.role === 'user' && !message.parts.some(part => part.functionResponse)"
          class="flex justify-end mb-4">
          <div class="bg-gray-200 rounded-lg py-2 px-4 shadow break-all max-w-full">
            <div v-for="(part, idx) in message.parts" :key="idx">
              <div v-if="part.text" class="text-gray-800 break-words">
                {{ part.text }}
              </div>
              <div v-else-if="part.tool_result" class="flex items-center text-gray-500 ">
                <Zap class="w-4 h-4 mr-2" />
                <span class="text-sm mt-0 truncate"
                  style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                  {{ part.tool_result.content }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- AI助手消息 -->
        <div v-else-if="message.role === 'model'" class="flex flex-col justify-start mb-4">
          <div class="bg-white text-gray-800 rounded-lg py-2 px-4 shadow break-words">
            <div v-for="(part, idx) in message.parts" :key="idx">
              <div v-if="part.text" v-html="markdown(part.text)"></div>

              <div v-else-if="part.functionCall" class="text-sm text-gray-500 mt-2 flex items-center ">
                <Loader v-if="!part.finished" class="animate-spin h-5 w-5 text-gray-500 mr-2" />
                <strong v-if="!part.finished">处理中...</strong>

                <!-- 根据 functionCall.name 显示不同的图标和文本 -->
                <Check v-if="part.finished && part.functionCall.name === 'start'" class="w-6 h-6 mr-2" />
                <strong v-if="part.finished && part.functionCall.name === 'start'">创建标题</strong>

                <Pencil v-if="part.finished && part.functionCall.name === 'write'" class="w-6 h-6 mr-2" />
                <strong v-if="part.finished && part.functionCall.name === 'write'">撰写内容</strong>

                <Pencil v-if="part.finished && part.functionCall.name === 'edit'" class="w-6 h-6 mr-2" />
                <strong v-if="part.finished && part.functionCall.name === 'edit'">编辑内容</strong>

                <Plus v-if="part.finished && part.functionCall.name === 'insert'" class="w-6 h-6 mr-2" />
                <strong v-if="part.finished && part.functionCall.name === 'insert'">插入内容</strong>

                <Trash v-if="part.finished && part.functionCall.name === 'delete'" class="w-6 h-6 mr-2" />
                <strong v-if="part.finished && part.functionCall.name === 'delete'">删除内容</strong>

                <XCircle v-if="part.finished && part.functionCall.name === 'clear'" class="w-6 h-6 mr-2" />
                <strong v-if="part.finished && part.functionCall.name === 'clear'">清空文档</strong>

                <Search v-if="part.finished && part.functionCall.name === 'searchWeb'" class="w-6 h-6 mr-2" />
                <strong v-if="part.finished && part.functionCall.name === 'searchWeb'">搜索互联网</strong>

                <Link2 v-if="part.finished && part.functionCall.name === 'getUrl'" class="w-6 h-6 mr-2" />
                <strong v-if="part.finished && part.functionCall.name === 'getUrl'">读取链接</strong>
              </div>
            </div>

            <div v-if="message.cost && message.cost.input && message.cost.output"
              class="mt-2 text-sm text-gray-400 flex space-x-4">
              <div>
                输入:{{ message.cost.input }}
              </div>
              <div>
                输出:{{ message.cost.output }}
              </div>
              <div>
                成本:${{ (message.cost.input * 0.000003 + message.cost.output * 0.000015).toFixed(6) }}
              </div>
            </div>
          </div>
        </div>

        <!-- 处理 functionResponse 消息 -->
        <div v-else-if="message.role === 'user' && message.parts.some(part => part.functionResponse)"
          class="flex justify-end mb-4">
          <div class="bg-gray-200 rounded-lg py-2 px-4 shadow break-all max-w-full">
            <div v-for="(part, idx) in message.parts" :key="idx">
              <div v-if="part.text" class="text-gray-800 break-words">{{ part.text }}</div>
              <div v-else-if="part.functionResponse" class="flex items-center text-gray-500">
                <span class="text-sm mt-0 truncate"
                  style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                  {{ part.functionResponse.response.content }}
                </span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- 输入区域 -->
    <div class="bg-white w-full p-4 pb-0">
      <div class="bg-gray-200 flex items-end rounded-[23px] p-[5px] shadow-md max-w-4xl mx-auto">
        <textarea v-model="newMessage" rows="1" :placeholder="thinking ? 'AI正在思考...' : '向AI发送消息'" @input="autoResize"
          @keydown.enter.prevent="sendMessage" ref="messageInput"
          class="ml-2 flex-1 p-2 border-none resize-none focus:outline-none overflow-auto min-h-[36px] max-h-[160px] bg-gray-200 custom-scrollbar"></textarea>
        <div @click="sendMessage" :class="[
          'text-2xl mr-1 ml-3 cursor-pointer rounded-full min-h-[38px] min-w-[38px] flex items-center justify-center',
          thinking ? 'bg-gray-400' : (newMessage === '' ? 'bg-gray-400' : 'bg-black')
        ]">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" fill="none" viewBox="0 0 32 32">
            <path fill="white" fill-rule="evenodd"
              d="M15.192 8.906a1.143 1.143 0 0 1 1.616 0l5.143 5.143a1.143 1.143 0 0 1-1.616 1.616l-3.192-3.192v9.813a1.143 1.143 0 0 1-2.286 0v-9.813l-3.192 3.192a1.143 1.143 0 1 1-1.616-1.616z" />
          </svg>
        </div>
      </div>
      <!-- 总成本显示 -->
      <div class="text-center text-gray-500 p-2 text-sm">总成本: ${{ totalCost }}</div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, computed, onMounted, nextTick } from 'vue';
import { marked } from 'marked';
import { Loader, Check, Pencil, Plus, Trash, XCircle, Search, Link2, Zap } from 'lucide-vue-next';

// 定义 props
const props = defineProps({
  model: {
    type: String,
    required: true,
  },
  tools: {
    type: Array,
    required: true,
  },
  getAppData: {
    type: Function,
    required: true,
  },
  runTool: {
    type: Function,
    required: true,
  },
});

// 定义响应式变量
const newMessage = ref('');
const messages = reactive([]);
const thinking = ref(false);
const chatContainer = ref(null);
const messageInput = ref(null);

// 初始化消息
onMounted(() => {
  scrollToBottom();
});

// 发送消息
const sendMessage = async () => {
  if (newMessage.value.trim() === '') return;

  // 添加用户消息，parts 为数组
  messages.push({
    role: 'user',
    parts: [
      {
        text: newMessage.value.trim(),
      },
    ],
  });
  thinking.value = true;
  newMessage.value = '';
  autoResize();
  scrollToBottom();
  await ai();
};

// 处理AI响应（已修改）
const ai = async () => {
  thinking.value = true;

  const requestBody = JSON.stringify({
    model: props.model,
    tools: props.tools,
    messages: prepareMessagesForAPI(),
    appData: props.getAppData(),
    max_tokens: 8129, // 根据需要设置
  });

  try {
    const response = await fetch('/api/ai/chat/gemini', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: requestBody,
    });

    if (!response.body) {
      throw new Error('ReadableStream 不可用');
    }

    // 添加一个新的 model 消息，初始化 parts 为数组，并添加 cost 对象
    const assistantMessage = {
      role: 'model',
      parts: [],
      cost: {
        input: 0,
        output: 0,
      },
    };
    messages.push(assistantMessage);

    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');

    let buffer = '';

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      buffer += decoder.decode(value, { stream: true });
      const lines = buffer.split('\n');

      // 处理所有完整的行
      for (let i = 0; i < lines.length - 1; i++) {
        const line = lines[i].trim();
        if (line.startsWith('data: ')) {
          const dataStr = line.slice(6);
          if (dataStr === '[DONE]') {
            thinking.value = false;
            break;
          }

          try {
            const data = JSON.parse(dataStr);
            if (data.candidates && data.candidates.length > 0) {
              const candidate = data.candidates[0];
              const parts = candidate.content.parts;

              parts.forEach(part => {
                if (part.text) {
                  // 处理文本内容
                  assistantMessage.parts.push({
                    text: part.text,
                  });
                }

                if (part.functionCall) {
                  // 处理函数调用
                  assistantMessage.parts.push({
                    functionCall: {
                      name: part.functionCall.name,
                      args: part.functionCall.args,
                    },
                  });
                }
              });

              // 更新成本信息
              if (data.usageMetadata) {
                assistantMessage.cost.input = data.usageMetadata.promptTokenCount || 0;
                assistantMessage.cost.output = data.usageMetadata.candidatesTokenCount || 0;
              }

              scrollToBottom();

              // 如果有函数调用，执行工具
              const toolCalls = assistantMessage.parts
                .filter(part => part.functionCall && !part.functionResponse)
                .map(part => ({
                  name: part.functionCall.name,
                  args: part.functionCall.args,
                }));

              if (toolCalls.length > 0) {
                thinking.value = false; // 停止思考状态，等待工具执行
                const toolResults = await props.runTool(toolCalls);
                if (Array.isArray(toolResults)) {
                  messages.push(...toolResults); // 展开数组并添加到 messages
                } else {
                  console.warn('runTool 返回的结果不是数组:', toolResults);
                }
                scrollToBottom();
                await ai(); // 继续AI响应
              }
            }
          } catch (error) {
            console.error('解析 JSON 数据时出错:', error);
          }
        }
      }

      // 保留未处理的部分
      buffer = lines[lines.length - 1];
    }

    thinking.value = false;
  } catch (error) {
    console.error('发送消息时出错:', error);
    thinking.value = false;
  }
};

// 计算所有消息的总成本
const totalCost = computed(() => {
  return messages.reduce((total, message) => {
    if (message.cost && message.cost.input && message.cost.output) {
      const cost = message.cost.input * 0.000003 + message.cost.output * 0.000015;
      return total + cost;
    }
    return total;
  }, 0).toFixed(6);
});

// 准备发送给API的消息格式，过滤掉 cost 字段
const prepareMessagesForAPI = () => {
  return messages.map((message) => {
    // 移除顶级的 cost 字段
    const { cost, ...rest } = message;

    // 如果 message.parts 存在，则移除 parts 子项中的 finished 字段
    if (rest.parts) {
      rest.parts = rest.parts.map((part) => {
        const { finished, ...partRest } = part; // 移除 finished
        return partRest;
      });
    }

    return rest;
  });
};

// 自动调整文本框高度
const autoResize = () => {
  nextTick(() => {
    const textarea = messageInput.value;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  });
};

// 自动滚动到最底部
const scrollToBottom = () => {
  nextTick(() => {
    const container = chatContainer.value;
    container.scrollTop = container.scrollHeight;
  });
};

// 转换 Markdown 内容
const markdown = (content) => {
  return marked(content);
};

// 发送开始消息
const sendStartMessage = async (message) => {
  messages.push({
    role: 'user',
    parts: [
      {
        text: message,
      },
    ],
  });
  thinking.value = true;
  await ai();
};

// 暴露方法给父组件
defineExpose({
  sendStartMessage,
});
</script>
